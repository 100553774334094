exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guide-js": () => import("./../../../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-license-agreement-js": () => import("./../../../src/pages/license-agreement.js" /* webpackChunkName: "component---src-pages-license-agreement-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-short-description-js": () => import("./../../../src/pages/short_description.js" /* webpackChunkName: "component---src-pages-short-description-js" */),
  "component---src-pages-ui-js": () => import("./../../../src/pages/ui.js" /* webpackChunkName: "component---src-pages-ui-js" */),
  "component---src-pages-user-agreement-js": () => import("./../../../src/pages/user-agreement.js" /* webpackChunkName: "component---src-pages-user-agreement-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

